import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "mb-5 mb-xl-8 card" }
const _hoisted_2 = { class: "card-header border-0 pt-5" }
const _hoisted_3 = { class: "card-title align-items-start flex-column" }
const _hoisted_4 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_5 = { class: "align-items-end flex-column" }
const _hoisted_6 = ["href", "onClick"]
const _hoisted_7 = { class: "menu-title" }
const _hoisted_8 = { class: "card-body py-3" }
const _hoisted_9 = { class: "table-responsive" }
const _hoisted_10 = { class: "table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3" }
const _hoisted_11 = { class: "fw-bolder text-muted" }
const _hoisted_12 = { class: "min-w-150px" }
const _hoisted_13 = { class: "min-w-120px" }
const _hoisted_14 = { class: "min-w-120px" }
const _hoisted_15 = { class: "min-w-120px" }
const _hoisted_16 = { class: "min-w-120px" }
const _hoisted_17 = { class: "min-w-100px text-end" }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary fs-6"
}
const _hoisted_20 = { class: "badge-success badge text-white" }
const _hoisted_21 = { class: "text-dark fw-bolder text-hover-primary fs-6" }
const _hoisted_22 = { class: "badge-light-success badge text-success" }
const _hoisted_23 = { class: "text-end" }
const _hoisted_24 = ["onClick"]
const _hoisted_25 = ["onClick"]
const _hoisted_26 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("pages.driveManagement.documents.docsList")), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_router_link, { to: "/administration/documents/documents/add" }, {
          default: _withCtx(({ href, navigate }) => [
            _createElementVNode("a", {
              class: "btn btn-primary btn-light btn-active-light-primary",
              href: href,
              onClick: navigate
            }, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("pages.driveManagement.documents.addDoc")), 1)
            ], 8, _hoisted_6)
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("table", _hoisted_10, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", _hoisted_11, [
              _createElementVNode("th", _hoisted_12, _toDisplayString(_ctx.$t("pages.driveManagement.documents.docName")), 1),
              _createElementVNode("th", _hoisted_13, _toDisplayString(_ctx.$t("pages.driveManagement.documents.docType")), 1),
              _createElementVNode("th", _hoisted_14, _toDisplayString(_ctx.$t("pages.driveManagement.documents.docCat")), 1),
              _createElementVNode("th", _hoisted_15, _toDisplayString(_ctx.$t("pages.driveManagement.documents.docTarget")), 1),
              _createElementVNode("th", _hoisted_16, _toDisplayString(_ctx.$t("pages.driveManagement.documents.docOrder")), 1),
              _createElementVNode("th", _hoisted_17, _toDisplayString(_ctx.$t("pages.driveManagement.documents.docActions")), 1)
            ])
          ]),
          (!_ctx.loading)
            ? (_openBlock(), _createElementBlock("tbody", _hoisted_18, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.documents, (document) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: document.id
                  }, [
                    _createElementVNode("td", null, [
                      _createElementVNode("a", _hoisted_19, _toDisplayString(document.label), 1)
                    ]),
                    _createElementVNode("td", null, [
                      _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.getFileType(document)), 1)
                    ]),
                    _createElementVNode("td", null, [
                      _createElementVNode("span", _hoisted_21, _toDisplayString(document.category.label), 1)
                    ]),
                    _createElementVNode("td", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(document.user_types, (userType) => {
                        return (_openBlock(), _createElementBlock("span", {
                          key: userType.id,
                          class: "badge-light badge text-primary me-3"
                        }, _toDisplayString(userType.label), 1))
                      }), 128))
                    ]),
                    _createElementVNode("td", null, [
                      _createElementVNode("span", _hoisted_22, _toDisplayString(document.order), 1)
                    ]),
                    _createElementVNode("td", _hoisted_23, [
                      _createElementVNode("a", {
                        onClick: ($event: any) => (_ctx.editDocument(document)),
                        href: "#",
                        class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                      }, _cache[0] || (_cache[0] = [
                        _createElementVNode("span", { class: "svg-icon svg-icon-3" }, [
                          _createElementVNode("svg", {
                            fill: "none",
                            viewBox: "0 0 24 24",
                            height: "24",
                            width: "24",
                            xmlns: "http://www.w3.org/2000/svg"
                          }, [
                            _createElementVNode("path", {
                              xmlns: "http://www.w3.org/2000/svg",
                              opacity: "0.3",
                              d: "M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z",
                              fill: "black"
                            }),
                            _createElementVNode("path", {
                              xmlns: "http://www.w3.org/2000/svg",
                              d: "M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z",
                              fill: "black"
                            })
                          ])
                        ], -1)
                      ]), 8, _hoisted_24),
                      _createElementVNode("a", {
                        onClick: ($event: any) => (_ctx.deleteDoc(document.id)),
                        class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                      }, _cache[1] || (_cache[1] = [
                        _createStaticVNode("<span class=\"svg-icon svg-icon-3\"><svg fill=\"none\" viewBox=\"0 0 24 24\" height=\"24\" width=\"24\" xmlns=\"http://www.w3.org/2000/svg\"><path xmlns=\"http://www.w3.org/2000/svg\" d=\"M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z\" fill=\"black\"></path><path xmlns=\"http://www.w3.org/2000/svg\" opacity=\"0.5\" d=\"M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z\" fill=\"black\"></path><path xmlns=\"http://www.w3.org/2000/svg\" opacity=\"0.5\" d=\"M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z\" fill=\"black\"></path></svg></span>", 1)
                      ]), 8, _hoisted_25)
                    ])
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_26, _cache[2] || (_cache[2] = [
              _createElementVNode("div", { class: "d-flex row-auto flex-center w-100 h-300px" }, [
                _createElementVNode("span", {
                  class: "spinner-border text-primary",
                  role: "status"
                })
              ], -1)
            ])))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}